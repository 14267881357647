.Title {
  margin-bottom: 1rem;
  font-family: "kubikmetaregular";
  font-size: 3rem;
  color: #525252;
}

.Bio {
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Abel", sans-serif;
  font-size: 2rem;
  font-weight: bold;
  color: #525252;

}

.Text {
  margin-top: 20px;
  margin-bottom: 0;
  font-family: "Abel", sans-serif;
  font-size: 1.5rem;
  color: #525252;
  font-weight: bold;

  width:auto;
}
.AllText{
  background: #F4F4EDBB;
}

.link,
.link.visted {
  display: inherit;
  display: inline-flex;
  font-family: "Abel", sans-serif;
  font-size: 2rem;
  color: #525252;
}
.Links{
  margin: 1rem;
}
.Links > * {
  margin: 0 1rem 0 0;
}
.Links > :last-child {
  margin: 0rem;
}


.link:hover {
  color: #8093f1;
}

.footer{
  

}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
body {
  margin: 0;
}
.P5Sketch {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: -1;
}

@font-face {
  font-family: "cubicregular";
  src: url("fonts/cubic-webfont.woff2") format("woff2"),
    url("fonts/cubic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "prismregular";
  src: url("fonts/prismfont_clv3-webfont.woff2") format("woff2"),
    url("fonts/prismfont_clv3-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "kubikmetaregular";
  src: url("fonts/kubikmeta-webfont.woff2") format("woff2"),
    url("fonts/kubikmeta-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
