.Title {
  margin-bottom: 1rem;
  font-family: "kubikmetaregular";
  font-size: 3rem;
  color: #525252;
}

.Bio {
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Abel", sans-serif;
  font-size: 2rem;
  font-weight: bold;
  color: #525252;

}

.Text {
  margin-top: 20px;
  margin-bottom: 0;
  font-family: "Abel", sans-serif;
  font-size: 1.5rem;
  color: #525252;
  font-weight: bold;

  width:auto;
}
.AllText{
  background: #F4F4EDBB;
}

.link,
.link.visted {
  display: inherit;
  display: inline-flex;
  font-family: "Abel", sans-serif;
  font-size: 2rem;
  color: #525252;
}
.Links{
  margin: 1rem;
}
.Links > * {
  margin: 0 1rem 0 0;
}
.Links > :last-child {
  margin: 0rem;
}


.link:hover {
  color: #8093f1;
}

.footer{
  

}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
body {
  margin: 0;
}
.P5Sketch {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: -1;
}

@font-face {
  font-family: "cubicregular";
  src: url(/static/media/cubic-webfont.ab8bef09.woff2) format("woff2"),
    url(/static/media/cubic-webfont.afd9cb0e.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "prismregular";
  src: url(/static/media/prismfont_clv3-webfont.daaa325d.woff2) format("woff2"),
    url(/static/media/prismfont_clv3-webfont.5927a418.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "kubikmetaregular";
  src: url(/static/media/kubikmeta-webfont.314113d0.woff2) format("woff2"),
    url(/static/media/kubikmeta-webfont.e6e68453.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

.App{
  width: 100%;
  margin-top: 8rem;

}

.Home {
  text-align: center;
  width: 80%;
  margin:auto;
}
.Title-Wrapper{
  /* border-color: white;
  border-style: solid; */
  background-color: #B388EBCC;
  -webkit-clip-path: polygon(
    0 8%,
    6% 0,
    94% 0,
    100% 8%,
    100% 92%,
    94% 100%,
    6% 100%,
    0% 92%,
    0% 8%
  );
          clip-path: polygon(
    0 8%,
    6% 0,
    94% 0,
    100% 8%,
    100% 92%,
    94% 100%,
    6% 100%,
    0% 92%,
    0% 8%
  )
}

.subpage{
  text-align: center;
  width: 80%;
  max-width: 1000px;
  align-items: center;
  display: flex;
  margin: auto;
  flex-direction: column; 
  justify-content: center;
  font-family: "Abel", sans-serif;
  font-size: 1.5rem;
  color: #525252;
 
}

.Back-Button{
  border: solid white .15rem;
  margin-top: 2rem;
  background-color: #B388EBCC;
  font-family: "Abel", sans-serif;
  font-size: 30px;
  font-weight: bold;
  color: #525252;
  margin-bottom: 2rem;
}

.Back-Button:hover{
  color: #8093f1;
}

.App-logo {
  height: 30vmin;
  margin: 0;
  pointer-events: none;
  align-self: center;
}

@-webkit-keyframes logoani {
  0% {
    transform: scale(0.90);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(0.90);
  }
}

@keyframes logoani {
  0% {
    transform: scale(0.90);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(0.90);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: logoani infinite 5s;
            animation: logoani infinite 5s;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    margin: 0 0 3rem 0
  }
}

.App-header {
  background-color: rgba(100, 0, 100, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;

}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Tile{
    display: flex;
    flex-direction: row;
    border: solid 0.5rem #525252;
    font-size: 0;
    background: #F4F4EDBB
}

.SWName{
    margin-top: 1.5rem;
    font-size: 2rem;
    font-weight: bold;
    color: #525252;
}
.SWImg{
    border: solid 0.5rem #525252BB;
    border-top-style: none;
    border-right-style: solid;
    border-bottom-style: none;
    border-left-style: none;
}

.SWDescription{
    font-size: 1.5rem;
    margin: 1rem;
}
.SWYear{
    font-size: 1rem;
}
.SWLink,
.SWLink.visted{
    margin-top: 1.5rem;
    font-size: 1.5rem;
    color: #525252;
}
.SWLink:hover{
    color: #8093f1;
}

.List > *{
    margin-top: 2rem;
}


